var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"menu"},[_c('div',{attrs:{"id":"nav"}},[_c('b-container',{staticClass:"menuContainer"},[_c('b-navbar',{attrs:{"toggleable":"lg","type":"dark","variant":"info"}},[_c('b-button',{staticClass:"burgerOuter",on:{"click":_vm.toggle}},[_c('b-img',{staticClass:"burger",attrs:{"src":_vm.config.VUE_APP_WP_DIGITAL_OCEAN + '2020/06/burger_menu.png'}})],1),(_vm.isMobile(true) && _vm.live_scoring == 'Y')?[(
                this.CurrentTitle == 'Live Scoring Monitor' ||
                  this.CurrentTitle.includes('Scores Only') ||
                  this.CurrentTitle.includes('Scoreboard')
              )?_c('b-nav-item',{staticClass:"menuBar LeaderboardBtnMob pulse",attrs:{"to":{
                name: 'reports-page',
                query: {
                  url: this.CurrentReport,
                  id: this.seasons,
                  code: this.code,
                  title: this.CurrentTitle
                }
              }}},[_vm._v("Leaderboard")]):_vm._e()]:_vm._e(),_c('b-navbar-brand',{attrs:{"to":"/"}},[_c('img',{staticClass:"logoImg",attrs:{"src":_vm.config.VUE_APP_WP_DIGITAL_OCEAN +
                  'sites/3/2020/06/LET20-LOGO-LET-Access-Series-White-1024x456-1.png'}})]),(_vm.live_scoring == 'Y')?[(
                this.CurrentTitle == 'Live Scoring Monitor' ||
                  this.CurrentTitle.includes('Scores Only') ||
                  this.CurrentTitle.includes('Scoreboard')
              )?_c('b-nav-item',{staticClass:"menuBar mobHide LeaderboardBtn pulse break",attrs:{"to":{
                name: 'reports-page',
                query: {
                  url: this.CurrentReport,
                  id: this.seasons,
                  code: this.code,
                  title: this.CurrentTitle
                }
              }}},[_vm._v(" Leaderboard ")]):_vm._e()]:_vm._e(),_c('b-collapse',{staticClass:"navbar-brand mx-auto",attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',{staticClass:"middleSec"},[_c('b-nav-item',{staticClass:"menuBar mobHide break",attrs:{"href":"https://solheimcup2023.eu/","target":"_blank"}},[_vm._v(" Solheim Cup ")]),_c('b-nav-item',{staticClass:"menuBar mobHide break",attrs:{"href":"https://www.lpga.com/","target":"_blank"}},[_vm._v("LPGA")]),_c('b-nav-item',{staticClass:"break menuBar mobHide rightLink LETAS",class:{
                  leadRight:
                    this.CurrentTitle == 'Live Scoring Monitor' ||
                    this.CurrentTitle.includes('Scores Only')
                },attrs:{"href":"https://ladieseuropeantour.com/","target":"_blank"}},[_vm._v("LET")])],1),_c('b-navbar-nav',{staticClass:"ml-auto"},[[_c('b-nav-item',{staticClass:"break menuBar mobHide socialIcon",attrs:{"href":"https://www.tiktok.com/@letaccess?lang=en","target":"_blank"}},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fab', 'tiktok']}})],1),_c('b-nav-item',{staticClass:"break menuBar mobHide socialIcon",attrs:{"href":'https://www.facebook.com/' + _vm.config.VUE_APP_FACEBOOK_NAME,"target":"_blank"}},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fab', 'facebook']}})],1),_c('b-nav-item',{staticClass:"break menuBar mobHide socialIcon",attrs:{"href":'https://twitter.com/' + _vm.config.VUE_APP_TWITTER_NAME,"target":"_blank"}},[_c('img',{staticClass:"newTwitter",attrs:{"src":"https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2023/09/logo-white.png"}})]),_c('b-nav-item',{staticClass:"break menuBar mobHide socialIcon",attrs:{"href":'https://www.instagram.com/' + _vm.config.VUE_APP_INSTA_NAME,"target":"_blank"}},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fab', 'instagram']}})],1),_c('b-nav-item',{staticClass:"break menuBar mobHide socialIcon",attrs:{"href":'https://www.youtube.com/user/' +
                      _vm.config.VUE_APP_YOUTUBE_NAME,"target":"_blank"}},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fab', 'youtube']}})],1),_c('b-nav-item',{staticClass:"break menuBar mobHide socialIcon",attrs:{"href":'https://www.flickr.com/photos/' +
                      _vm.config.VUE_APP_FLICKR_NAME,"target":"_blank"}},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fab', 'flickr']}})],1)]],2)],1)],2)],1)],1)]),_c('transition',{attrs:{"name":"slide"}},[_c('div',{staticClass:"slidein",class:_vm.open ? 'open' : ''},[_c('b-row',[_c('b-col',[_c('b-nav-item',{staticClass:"mainItem",attrs:{"to":"/"},on:{"click":_vm.toggle}},[_vm._v("Home")]),_c('b-nav-item',{staticClass:"mainItem",attrs:{"to":'/tournaments-information'},on:{"click":_vm.toggle}},[_vm._v(" Tournaments ")]),_c('b-nav-item',{staticClass:"mainItem",attrs:{"to":"/news"},on:{"click":_vm.toggle}},[_vm._v("News")]),_c('b-nav-item',{staticClass:"mainItem",attrs:{"to":{
              name: 'players'
            }},on:{"click":_vm.toggle}},[_vm._v("Players")]),_c('b-nav-item',{staticClass:"mainItem",attrs:{"to":{
              name: 'videos',
              query: { id: '' }
            }},on:{"click":_vm.toggle}},[_vm._v("Videos")]),_c('b-nav-item',{staticClass:"mainItem",attrs:{"to":"/the-letas-golf-podcast"},on:{"click":_vm.toggle}},[_vm._v("The LETAS Golf Podcast")]),_c('b-nav-item',{staticClass:"mainItem statsUpdate",attrs:{"to":{
              name: 'stats',
              query: { id: _vm.seasons, full: true },
            }},on:{"click":_vm.toggle}},[_vm._v("Stats")]),_c('b-nav-item',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('collapse-2'),expression:"'collapse-2'"}],staticClass:"rankingsMenu mainItem"},[_vm._v(" Rankings "),_c('font-awesome-icon',{staticClass:"arrowDown",attrs:{"icon":['fas', 'arrow-down']}})],1),_c('b-collapse',{attrs:{"id":"collapse-2"}},[_c('ul',[_c('li',{staticClass:"liRanksLinks"},[_c('b-nav-item',{staticClass:"rankLinks",attrs:{"to":{
                    name: 'letas-order-of-merits',
                    query: { id: _vm.seasons, oom: 'LO' }
                  }},on:{"click":_vm.toggle}},[_vm._v("Order of Merit")])],1),_c('li',{staticClass:"liRanksLinks"},[_c('b-nav-item',{staticClass:"rankLinks",attrs:{"to":{
                    name: 'letas-roty',
                    query: { id: _vm.seasons, oom: 'LR' }
                  }},on:{"click":_vm.toggle}},[_vm._v("Rookie of the Year")])],1),_c('li',{staticClass:"liRanksLinks"},[_c('b-nav-item',{staticClass:"rankLinks",attrs:{"to":{
                    name: 'rolex-world-rankings-2',
                    query: { id: _vm.seasons, oom: 'WR' }
                  }},on:{"click":_vm.toggle}},[_vm._v("Rolex Ranking")])],1)])]),_c('b-nav-item',{staticClass:"mainItem",attrs:{"to":'/celebrating-the-green/'},on:{"click":_vm.toggle}},[_vm._v("Celebrating The Green")]),_c('b-nav-item',{staticClass:"mainItem",attrs:{"to":"/partners-suppliers"},on:{"click":_vm.toggle}},[_vm._v("Partners & Suppliers")]),_c('b-nav-item',{staticClass:"mainItem",attrs:{"to":"/about-letas-tour"},on:{"click":_vm.toggle}},[_vm._v("About LETAS")]),_c('b-nav-item',{staticClass:"mainItem",attrs:{"to":"/membership"},on:{"click":_vm.toggle}},[_vm._v("Membership")])],1),_c('b-col',{staticClass:"FeaturedNews-mob"},[_c('h3',{staticClass:"menuFeat"},[_vm._v(" Featured News ")]),_c('MenuFeat')],1)],1),_c('b-row',{staticClass:"BannerHideMob"},[_c('b-col',[_c('b-nav-item',{directives:[{name:"b-modal",rawName:"v-b-modal.app",modifiers:{"app":true}}]},[_c('b-img',{staticClass:"menuBanner",attrs:{"src":_vm.config.VUE_APP_WP_DIGITAL_OCEAN+'2021/03/follow-1.jpeg'}})],1),_c('b-modal',{attrs:{"centered":"true","hide-footer":"true","hide-header":"true","id":"app"}},[_c('b-row',[_c('b-col',[_c('a',{attrs:{"href":_vm.config.VUE_APP_GOOGLE_PLAY,"target":"_blank"}},[_c('b-img',{staticClass:"appLogo",attrs:{"src":"https://ocs-sport.ams3.cdn.digitaloceanspaces.com/tartanprotour/2021/03/Mask-Group-4.png"}})],1)]),_c('b-col',[_c('a',{attrs:{"href":_vm.config.VUE_APP_APPLE_STORE,"target":"_blank"}},[_c('b-img',{staticClass:"appLogo",attrs:{"src":"https://ocs-sport.ams3.cdn.digitaloceanspaces.com/tartanprotour/2021/03/Mask-Group-5.png"}})],1)])],1)],1)],1)],1),_c('button',{staticClass:"close-btn",on:{"click":_vm.toggle}},[_vm._v("×")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }